import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    // ImageInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useTranslate,
    BooleanInput,
    required,
} from 'react-admin';
import { Box } from '@material-ui/core';
// import Aside from './Aside';
// import ThumbnailField from '../products/ThumbnailField';
// import ProductRefField from '../products/ProductRefField';
import { FieldProps, Organisation } from '../types';

const OrganisationTitle: FC<FieldProps<Organisation>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.organisations.name', { smart_count: 1 })}{' '}
            &quot;
            {record.title_fr}&quot;
        </span>
    ) : null;
};

const OrganisationCreate = (props: any) => (
    <Create
        title={<OrganisationTitle />}
        {...props}
        // aside={<Aside />}
        undoable={false}
    >
        <SimpleForm redirect="list">
            <>
                {/* <ImageInput
                source="image"
                label="resources.organisations.image.upload_single"
                labelSingle="resources.organisations.fields.image"
                accept="image/*"
                validate={[required()]}
                multiple={false}
                fullWidth
            >
                <ImageField accept="image/*" source="imageUrl" title="title" />
            </ImageInput> */}
                <TextInput
                    source="name"
                    validate={[required()]}
                    label="resources.organisations.fields.name"
                    fullWidth
                />
                  <SelectInput
                    label="resources.organisations.fields.type"
                    source="type"
                    validate={[required()]}
                    choices={[
                        { id: 'sb', name: 'Single Brand' },
                        { id: 'mb', name: 'Multi Brand' },
                    ]}
                    alwaysOn
                />
                <TextInput
                    source="description"
                    // validate={[required()]}
                    label="resources.organisations.fields.description"
                    fullWidth
                />
                <Box display={{ md: 'block', lg: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                            source="admin.name.first"
                            validate={[required()]}
                            label="resources.organisations.fields.adminFirstName"
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                            source="admin.name.last"
                            validate={[required()]}
                            label="resources.organisations.fields.adminLastName"
                            fullWidth
                        />
                    </Box>
                </Box>
                <TextInput
                    source="admin.email"
                    type="email"
                    validation={{ email: true }}
                    validate={[required()]}
                    label="resources.organisations.fields.adminEmail"
                    fullWidth
                />
                <TextInput
                    source="admin.phone"
                    validate={[required()]}
                    label="resources.organisations.fields.adminPhone"
                    fullWidth
                />
              
                <BooleanInput
                    source="isEnabled"
                    label="resources.organisations.fields.isEnabled"
                    fullWidth
                />
            </>
        </SimpleForm>
    </Create>
);

export default OrganisationCreate;
