import * as React from 'react';
import { useState, useEffect } from 'react';
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';

// import admins from './admins';
// import users from './users';
import organisations from './organisations';

import dataProviderFactory from './dataProvider';

const messages = {
    fr: frenchMessages,
    en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(
    locale => (messages[locale] ? messages[locale] : messages.en),
    resolveBrowserLocale()
);

const App = () => {
    const [dataProvider, setDataProvider] = useState(null);
    // const visibility = localStorage.getItem('visibility');
    useEffect(() => {
        let restoreFetch;

        const fetchDataProvider = async () => {
            const dataProviderInstance = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER || ''
            );

            restoreFetch = dataProviderInstance;

            setDataProvider(
                // GOTCHA: dataProviderInstance can be a function
                () => dataProviderInstance
            );
        };

        fetchDataProvider();

        return restoreFetch;
    }, []);

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            // dashboard={(() =>
            //     visibility === 'admin'
            //         ? Dashboard
            //         : visibility === 'regional'
            //         ? Dashboard
            //         : visibility === 'national'
            //         ? Dashboard
            //         : null)()}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            {(permissions: string) => {
                return [
                    // Only admin can view resource
                    // permissions === 'admin' ? (
                    //     <Resource name="admins" {...admins} />
                    // ) : null,
                    // permissions === 'sysadmin' ? (
                    //     <Resource name="users" {...users} />
                    // ) : null,
                    permissions === 'sysadmin' ? (
                        <Resource name="organisations" {...organisations} />
                    ) : null,



                ];
            }}
        </Admin>
    );
};

export default App;
