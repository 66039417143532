import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Entrer',
            password: 'Mot de Pass',
            sign_in_error: "Erreur d'Authentification Failed",
        },
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            monthly_revenue: 'CA à 30 jours',
            month_history: "Chiffre d'affaire sur 30 jours",
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            new_customers: 'Nouveaux clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items:
                    'par %{customer_name}, un produit |||| par %{customer_name}, %{nb_items} produits',
            },
            welcome: {
                title: 'Bienvenu chez COSMIND',
                subtitle: 'COSMIND',
                aor_button: 'Site web du COSMIND',
                demo_button: 'Site web du COSMIND',
            },
        },
        menu: {
            sales: 'Ventes',
            catalog: 'Catalogue',
            users: 'Utilisateurs',
            annuaire: 'Annuaire',
        },
    },
    resources: {
        users: {
            name: 'Etudiant |||| Etudiants',
            fields: {
                firstName: 'Prénom',
                lastName: 'Nom',
                email: 'Email',
                emailValidated: 'Email validé',
                promoBox: 'Boite de promo',
                accountEnabled: 'Activé',
                accountEnabledByAdmin: 'Activé par admin',
                isRegional: 'Responsable régional',
                isNational: 'Responsable national',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                priceCategory: 'Tranche de prix',
                additionalRegions: 'Additinal regions',
                commands: 'Commandes',
                groups: 'Segments',
                total_spent: 'Dépenses',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        admins: {
            name: 'Administrateur |||| Administrateurs',
            fields: {
                firstName: 'Prénom',
                lastName: 'Nom',
                image: 'Image',
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                priceCategory: 'Tranche de prix',

                commands: 'Commandes',
                groups: 'Segments',
                total_spent: 'Dépenses',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                image: 'Image',
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        organisations: {
            name: 'Organisation |||| Organisations',
            fields: {
                name: 'Nom',
                type: 'Type',
                description: 'Description',
                isEnabled: 'Activée',
                image: 'Image',
                file: 'Document',
                date: 'Date',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                adminFirstName: "Prénom de l'admin",
                adminLastName: "Nom de l'admin",
                adminEmail: "Email de l'Admin",
                adminPhone: "Tél de l'Admin",
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
            file: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez un fichier pdf à uploader ou cliquez pour le sélectionner.',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
    },
};

export default customFrenchMessages;
