import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    // ImageInput,
    // ImageField,
    SimpleForm,
    TextInput,
    useTranslate,
    BooleanInput,
    // FileField,
    required,
} from 'react-admin';

// import Aside from './Aside';
import { FieldProps, Organisation } from '../types';

const OrganisationTitle: FC<FieldProps<Organisation>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.organisations.name', { smart_count: 1 })} &quot;
            {record.title_fr}&quot;
        </span>
    ) : null;
};

const OrganisationEdit = (props: any) => (
    <Edit
        title={<OrganisationTitle />}
        {...props}
        // aside={<Aside />}
        undoable={false}
    >
        <SimpleForm redirect="list">
        <TextInput
                source="name"
                validate={[required()]}
                label="resources.organisations.fields.name"
                fullWidth
            />
            <TextInput
                source="description"
                // validate={[required()]}
                label="resources.organisations.fields.description"
                fullWidth
            />
            {/* <ImageInput
                source="image"
                label="resources.organisations.image.upload_single"
                labelSingle="resources.organisations.fields.image"
                accept="image/*"
                validate={[required()]}
                multiple={false}
                fullWidth
            >
                <ImageField accept="image/*" source="imageUrl" title="title" />
            </ImageInput> */}


   <BooleanInput
                                                source="isEnabled"
                                                label="resources.organisations.fields.isEnabled"
                                                fullWidth
                                            />
        </SimpleForm>
    </Edit>
);

export default OrganisationEdit;
