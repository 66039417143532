import BusinessIcon from '@material-ui/icons/Business';

import List from './List';
import Edit from './Edit';
import Create from './Create';

export default {
    list: List,
    create: Create,
    edit: Edit,
    icon: BusinessIcon,
};
