import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    SearchInput,
    SelectField,
    SelectInput,
} from 'react-admin';
import ColoredBooleanField from './ColoredBooleanField';
const DocumentFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput
            label="resources.organisations.fields.type"
            source="type"
            choices={[
                { id: 'sb', name: 'Single Brand' },
                { id: 'mb', name: 'Multi Brand' },
            ]}
            emptyText="Tout"
            alwaysOn
        />
    </Filter>
);

const DocumentList = (props: any) => {
    return (
        <List
            {...props}
            filters={<DocumentFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            bulkActionButtons={false}
            // bulkActionButtons={<ClientsBulkActionButtons />}
            exporter={false}
            // aside={<DocumentListAside />}
        >
            <Datagrid optimized rowClick="edit">
                <TextField
                    label="resources.organisations.fields.name"
                    source="name"
                />
                <SelectField
                    label="resources.organisations.fields.type"
                    source="type"
                    choices={[
                        { id: 'sb', name: 'Single Brand' },
                        { id: 'mb', name: 'Multi Brand' },
                    ]}
                />
                <TextField
                    label="resources.organisations.fields.description"
                    source="description"
                />
                <TextField
                    source="admin.name.first"
                    label="resources.organisations.fields.adminFirstName"
                />
                <TextField
                    source="admin.name.last"
                    label="resources.organisations.fields.adminLastName"
                />
                <TextField
                    source="admin.email"
                    label="resources.organisations.fields.adminEmail"
                />
                <TextField
                    source="admin.phone"
                    label="resources.organisations.fields.adminPhone"
                />

                <ColoredBooleanField
                    source="isEnabled"
                    label="resources.organisations.fields.isEnabled"
                />
                <DateField
                    source="created_at"
                    locales="fr-FR"
                    showTime
                    label="resources.organisations.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default DocumentList;
