import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';

// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import {
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    // Typography,
} from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
// import LockIcon from '@material-ui/icons/Lock';
// import bgImage from './bgImage.webp';
// import cosminIcon from './icone.png';

import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import { lightTheme } from './themes';

const useStyles = makeStyles(theme => ({
    media:{
        borderWidth: '0em',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'end',
        // justifyContent: 'flex-start',
        // background: `url(${bgImage})`,
        // background: 'url(https://source.unsplash.com/collection/9026761/1600x900)',
        background: 'url(https://source.unsplash.com/1600x900/?space)',

        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    card: {
        minWidth: 300,
        marginTop: '10em',
        marginRight: '4em',
        borderWidth: '0em',
        // backgroundColor:'transparent'
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        // padding: '0 1em 1em 1em',
        // alignItems: 'end',
    },
}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

interface FormValues {
    username?: string;
    password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation<{ nextPathname: string } | null>();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            (error: Error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'global.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            }
        );
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>

                        <Card className={classes.card}>
                            <CardActionArea>
                                 <CardMedia
                                    className={classes.media}
                                    component="img"
          alt="Cosmind"
          height="50"
          width="100"
          image={require('./icone.png')} // require image"
                                    title="Cosmind"
                                />
                                {/* <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar> */}
                                <CardContent>
                                    {/* <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="h2"
                                    >
                                        Cosmind
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                    </Typography> */}
                                    <div className={classes.form}>
                                        <div className={classes.input}>
                                            <Field
                                                autoFocus
                                                name="username"
                                                // @ts-ignore
                                                component={renderInput}
                                                label={translate(
                                                    'global.auth.email'
                                                )}
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className={classes.input}>
                                            <Field
                                                name="password"
                                                // @ts-ignore
                                                component={renderInput}
                                                label={translate(
                                                    'global.auth.password'
                                                )}
                                                type="password"
                                                disabled={loading}
                                            />
                                        </div>
                                    </div>
                                </CardContent>
                            </CardActionArea>

                                <CardActions className={classes.actions}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        fullWidth
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                            />
                                        )}
                                        {translate('global.auth.sign_in')}
                                    </Button>
                                </CardActions>

                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={lightTheme}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
